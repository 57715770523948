import React from "react";
import axios from 'axios';
import { useState } from 'react';
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import OrderShipToDropDown from "./OrderShipToDropDown";
import { Link, redirect, Navigate, useNavigate, json } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { fakeBaseQuery } from "@reduxjs/toolkit/query";


// Page Name - Order Cart

const OrderSummary = () => {
    const navigateurl = useNavigate();
    const [selectVal, setSelectVal] = useState("");
    const [warnData, setwarnData] = useState("");
    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const [inputs, setInputs] = useState({});
    const [txtOrderCommentsTextForState, setOrderCommentsText] = useState("")

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }
    function setBasicDistId(inputs) {
        inputs.distributorId = distID;
    }
    const { data, error, isLoaded } = GetRequest(`/OrderCart/GetActiveOrderCart/${distID}`);

    if (error !== null) {
        return <Error data=" " />;
    }
    if (!isLoaded) {
        return <Loading />;
    }
    const tostDismissAll = () => toast.clearWaitingQueue();
    const notifySuccess = () => toast.success('Order Submitted successfully !', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
    const notifySuccessAddChanel = () => toast.success('Channel Partner added successfully!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
    const notifySuccessItem = () => toast.success('Cart Item added successfully!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
    const notifyErrorItem = () => toast.error('Cart Item count removed successfully!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
    const notifyErrorDeleteItem = () => toast.error('Cart Item removed successfully!', {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored"
    });
    const modalWarnClose = () => {
        document.getElementById('showSubmitOrderDetailedModal').style.display = 'none';
        document.getElementById('showSubmitOrderDetailedModal').classList.add('hide');
        document.getElementById('showSubmitOrderDetailedModal').classList.remove('show');
    }

    const modalWarnCloseNoItemsInOrderCart = () => {
        document.getElementById('divModalNoItemsInOrderCart').style.display = 'none';
        document.getElementById('divModalNoItemsInOrderCart').classList.add('hide');
        document.getElementById('divModalNoItemsInOrderCart').classList.remove('show');
    }

    const handleCloseModalOrderIsBeingSaved = () => {
        document.getElementById('divOrderIsBeingSaved').style.display = 'none';
        document.getElementById('divOrderIsBeingSaved').classList.add('hide');
        document.getElementById('divOrderIsBeingSaved').classList.remove('show');
    }

    const handleSelectChange = (event) => {
        setSelectVal(event.target.value);
    }

    const proceedAnywayToSubmitOrder = (event) => {
        let chkDT = true;
        handleSubmitOrder(event, chkDT);
    }
    const handleSubmitOrder = (event, chkDT) => {

        event.preventDefault();

        //alert(data.result.orderCartId);
        if (data === null || data.result === null || data.result.orderCartLineItemDTOList === null || data.result.orderCartLineItemDTOList.length === 0) {
            document.getElementById('divModalNoItemsInOrderCart').style.display = 'block';
            document.getElementById('divModalNoItemsInOrderCart').classList.add('show');
            return;
        }
        //console.log(txtOrderCommentsTextForState);
        // alert(txtOrderCommentsTextForState);
        //  const checkProceedOrderTrueFalse = checkProceedOrder();

        if (chkDT == true || checkProceedOrder() == true) {
            modalWarnClose();
            const fData = {};
            fData.distributorId = parseInt(distID);
            fData.channelPartnerId = String(selectVal);
            fData.orderComments = String(txtOrderCommentsTextForState);

            document.getElementById('divOrderIsBeingSaved').style.display = 'block';
            document.getElementById('divOrderIsBeingSaved').classList.add('show');

            axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/Orders/SubmitOrder", fData, {
                headers: {
                    "Cache-Control": "no-cache",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': localStorage.getItem("loginToken"),
                },
            })
                .then((response) => {
                    handleCloseModalOrderIsBeingSaved();
                    if (response.data.isSuccess === true) {
                        tostDismissAll();
                        notifySuccess();
                        localStorage.setItem("ditsCurrentOrderLineId", response.data.result.orderLineId);
                        localStorage.removeItem('activeCartData');
                        redirect("/OrderTransactionDetails");
                        <Navigate to="/OrderTransactionDetails" />;
                        navigateurl('/OrderTransactionDetails', { replace: true });
                    }
                    else {
                        alert("Error while submitting order. - " + response.data.message + "-Error Code - " + response.data.errorCode);
                    }
                    modalWarnClose();
                }).catch((error) => {
                    handleCloseModalOrderIsBeingSaved();
                    console.log("Logging Error");
                    console.log(error);
                    // alert("Error while submitting order, Please try again." + error.code + error.response.status + error.response.data);
                    alert("Error while submitting order, Please try again." + error.code + "-" + error.response.status);
                    //console.log(error.statusText);
                    tostDismissAll();
                    modalWarnClose();
                    // if(response.response.status === 401){  
                    //     window.location.replace("/Logout");
                    //  }
                });
        }
    }

    const checkProceedOrder = () => {

        //  alert(data.result.orderCartId);
        const actNum = document.getElementById('activeOrderCartCard').value;
        //const actNum =data.result.orderCartId;
        // alert(actNum);
        axios.get(`https://erelatevmdev.centralindia.cloudapp.azure.com/api/OrderCart/AnalyzeAndGetMOQWarningForProductsInOrderCartBeforeOrder/${actNum}`, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then(function (response) {
                if (response.data.result !== null) {
                    setwarnData(response.data);
                    document.getElementById('showSubmitOrderDetailedModal').style.display = 'block';
                    document.getElementById('showSubmitOrderDetailedModal').classList.add('show');
                    return false;
                } else {
                    return true;
                }
            })
            .catch(function (error) {
                return false;
            });
    }

    const minusItemProductCount = (event) => {
        let currValMin = event.currentTarget.nextSibling.value;
        if (parseInt(currValMin) !== 0) {
            currValMin = parseInt(currValMin) - 1;
            event.currentTarget.nextSibling.value = currValMin;
            const formData = {};
            formData.orderCartId = document.getElementById("orderCartIdChangeQty").value;
            formData.orderCartLineItemId = event.currentTarget.id;
            formData.productItemId = event.currentTarget.attributes.getNamedItem('data-val-productItemId').value;

            // alert(formData.productItemId);
            formData.quantity = currValMin;
            formData.distributorId = distID;
            axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/OrderCart/ChangeQuantityOfProductInCart", formData, {
                headers: {
                    "Cache-Control": "no-cache",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': localStorage.getItem("loginToken"),
                },
            })
                .then((response) => {
                    if (response.data.isSuccess === true) {
                        document.getElementById(`updateTotal${response.data.result.orderCartLineItem.id}`).innerText = response.data.result.orderCartLineItem.totalCost;
                        tostDismissAll();
                        notifyErrorItem();

                        if (formData.quantity === 0) {
                            document.getElementById(`forRemove${formData.orderCartLineItemId}`).classList.add('hide');
                        }
                    }

                }).catch((response) => {
                    // if(response.response.status === 401){  
                    //     window.location.replace("/Logout");
                    //  }
                    console.log(response.statusText);
                });
        }
    }

    const plusItemProductCount = (event) => {
        let currVal = event.currentTarget.previousSibling.value;
        currVal = parseInt(currVal) + 1;
        event.currentTarget.previousSibling.value = currVal;
        const formData = {};
        formData.orderCartId = document.getElementById("orderCartIdChangeQty").value;
        formData.orderCartLineItemId = event.currentTarget.id;
        formData.productItemId = event.currentTarget.attributes.getNamedItem('data-val-productItemId').value;
        formData.quantity = currVal;
        formData.distributorId = distID;
        axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/OrderCart/ChangeQuantityOfProductInCart", formData, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.data.isSuccess === true) {
                    document.getElementById(`updateTotal${response.data.result.orderCartLineItem.id}`).innerText = response.data.result.orderCartLineItem.totalCost;
                    tostDismissAll();
                    notifySuccessItem();
                }
                modalWarnClose();
            }).catch((response) => {
                // if(response.response.status === 401){  
                //     window.location.replace("/Logout");
                //  }
                console.log(response.statusText);
            });
    }
    const deleteCartOrderItem = (event) => {
        event.preventDefault();
        document.getElementById("orderCartItemLineIdDelete").value = event.currentTarget.id;
        const formDeleteItem = {};
        formDeleteItem.orderCartLineItemId = event.currentTarget.id;
        axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/OrderCart/RemoveProductFromCart", formDeleteItem, {
            headers: {
                "Cache-Control": "no-cache",
                "Access-Control-Allow-Origin": "*",
                'Authorization': localStorage.getItem("loginToken"),
            },
        })
            .then((response) => {
                if (response.data.isSuccess === true) {
                    const remot = document.getElementById("orderCartItemLineIdDelete").value;
                    document.getElementById(`forRemove${remot}`).classList.add('hide');
                    tostDismissAll();
                    notifyErrorDeleteItem();
                }
                modalWarnClose();
            }).catch((response) => {
                // if(response.response.status === 401){  
                //     window.location.replace("/Logout");
                //  }
                console.log(response.statusText);
            });
    }
    const handleAddChannelPartnerSubmit = (event) => {
        event.preventDefault();
        if (Object.keys(inputs).length !== 0) {
            setBasicDistId(inputs);
            axios.post("https://erelatevmdev.centralindia.cloudapp.azure.com/api/DistributorChannelPartner/QuickAddDistributorChannelPartners", inputs, {
                headers: {
                    "Cache-Control": "no-cache",
                    "Access-Control-Allow-Origin": "*",
                    'Authorization': localStorage.getItem("loginToken"),
                },
            })
                .then((response) => {
                    if (response.status === 200) {
                        tostDismissAll();
                        notifySuccessAddChanel();
                        setSelectVal(response.data.result.id);
                        document.getElementById('lastAddedShpTemp').value = response.data.result.id;
                        document.getElementById('lastAddedShpTemp').text = response.data.result.name;
                        document.getElementById('name').value = '';
                        document.getElementById('phoneNumber1').value = '';
                        document.getElementById('addressLine1').value = '';
                        document.getElementById('addressLine2').value = '';
                        document.getElementById('addressLine3').value = '';
                        document.getElementById('pinCode').value = '';
                    }
                }).catch((response) => {
                    // if(response.response.status === 401){  
                    //     window.location.replace("/Logout");
                    //  }
                    console.log(response.statusText);
                });
        } else {

        }
    }

    // setTimeout(function(){  
    //     if(data.result.hasOwnProperty('orderCartLineItemDTOList')){
    //         const cartData = data.result.orderCartLineItemDTOList;
    //         localStorage.setItem('OredrCartProductData', JSON.stringify(cartData));
    //     } 
    // }, 500)

    return (

        <div className="row">
            <div className="col-md-12">
                <div className="card noborder rmPdBor">
                    <div className="card-body">
                        <input type="hidden" id="orderCartItemLineIdDelete" value="" />
                        <input type="hidden" id="orderCartIdChangeQty" value={data.result.orderCartId} />
                        {
                            data.result.orderCartLineItemDTOList && data.result.orderCartLineItemDTOList.map((item, index) => (
                                <div className='row sepLn' id={`forRemove${item.orderCartLineItemId}`} data-state-id={item.productItemId}>
                                    <div className='col-sm-3'><p>  {item.productName}  </p></div>
                                    <div className='col-sm-3'>
                                        <div className="d-flex flex-row">
                                            <button className="btn text-danger" onClick={minusItemProductCount} id={item.orderCartLineItemId} data-val-productItemId={item.productItemId}>
                                                <i className="fa fa-minus"></i>
                                            </button>
                                            <input name="quantity" value={item.qty} type="number" id="OrderSummCartProductCount" className="form-control qtyW50" data-state-id={item.productItemId} data-val-productItemId={item.productItemId} />
                                            <button className="btn text-primary" onClick={plusItemProductCount} id={item.orderCartLineItemId} data-val-productItemId={item.productItemId}>
                                                <i className="fa fa-plus"></i>
                                            </button>
                                        </div>
                                        <span className="fa fa-close deleteCartOrderItem" onClick={deleteCartOrderItem} id={item.orderCartLineItemId} data-val-productItemId={item.productItemId}></span>

                                    </div>
                                    <div className='col-sm-2'> <p>Rate -  {item.rate}Rs </p></div>
                                    <div className='col-sm-4'>  <p>Total - <b id={`updateTotal${item.orderCartLineItemId}`}> {item.cost}</b> <b>Rs</b>
                                    </p>
                                    </div>

                                </div>
                            ))
                        }
                        <input type="hidden" value={data.result.orderCartId} id="activeOrderCartCard" />
                        <div className="row">
                            <div className='col-md-12 padBottomSubmit'>
                                <form onSubmit={handleSubmitOrder} >
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <h6 for="Order Ship To " className="form-label">Order Ship To  <i className="fa fa-plus addnewbka" data-bs-toggle="modal" data-bs-target="#AddNewShipToModal" ></i></h6>

                                                <select className="form-control drselopt" name='OrderShipTo' value={selectVal} onChange={handleSelectChange}>
                                                    <option id="lastAddedShpTemp" value="0"> Not Specified </option>
                                                    <OrderShipToDropDown />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <h6 for="Remarks " className="form-label" style={{ marginTop: "5px" }}>Remarks </h6>
                                                <textarea maxLength={230} className="form-control" name="txtOrderCommentsText" type="text" id="txtOrderCommentsText" value={txtOrderCommentsTextForState} onChange={e => setOrderCommentsText(e.target.value)}> </textarea>
                                            </div>
                                        </div>
                                        <div className="col-sm-4" >
                                            <div className="form-group padBtnBto">
                                                <input className="btn btn-primary " type="submit" value="Submit Order" id="btnSubmitOrder" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="divOrderIsBeingSaved"  >
                        <div className="modal-dialog ">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Order Save in progress</h4>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleCloseModalOrderIsBeingSaved}  ></button>
                                </div>
                                <div className="row">
                                    <div className="modal-body">
                                        <div className="card card-warning">
                                            <div className="card-body">
                                                <div className="alert alert-info alert-dismissible">
                                                    Submitting Your Order. Please wait.
                                                    <br /> You will be redirected to order summary.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={handleCloseModalOrderIsBeingSaved} data-bs-dismiss="modal">Close</button>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="divModalNoItemsInOrderCart"  >
                        <div className="modal-dialog ">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">No Items Selected</h4>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={modalWarnCloseNoItemsInOrderCart}  ></button>
                                </div>
                                <div className="row">
                                    <div className="modal-body">
                                        <div className="card card-warning">
                                            <div className="card-header">
                                                <h3 className="card-title">
                                                    <i className="fa fa-exclamation-triangle"></i>
                                                    Alerts
                                                </h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="alert alert-info alert-dismissible">
                                                    Please select And add items to order cart.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" onClick={modalWarnCloseNoItemsInOrderCart} data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="showSubmitOrderDetailedModal"  >
                        <div className="modal-dialog ">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title">Order Details</h4>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={modalWarnClose}  ></button>
                                </div>
                                <div className="row">
                                    <div className="modal-body">
                                        <div className="card card-warning">
                                            <div className="card-header">
                                                <h6 className="card-title">
                                                    {warnData != null && warnData.result != null ? <> <h6>{warnData.result.orderForDetails}</h6> </> : ''}
                                                </h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="alert alert-info alert-dismissible">
                                                    {warnData.result && (warnData.result.isOrderCartValid === false || warnData.result.IsOrderQuantityAsPerStandard === false) ?
                                                        <div>
                                                            <div className="row"> <div className="col-md-12">{warnData.result.orderValidationResultMessage}</div> </div>
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <table className="table table-sm">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Item</th>
                                                                                <th>Qty in Order</th>
                                                                                <th>Min. Order Qty</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody >
                                                                            {
                                                                                warnData.result && warnData.result.orderCartLineItemDTOList.map((item, index) => (

                                                                                    item.isMinQtyConditionMatched === false ?
                                                                                        <tr>
                                                                                            <td>{item.productName}
                                                                                            </td>
                                                                                            <td>{item.qty}
                                                                                            </td>
                                                                                            <td>
                                                                                                {item.minQtyRequiredForOrder}
                                                                                            </td>
                                                                                        </tr> : ''
                                                                                ))
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : 'Please Proceed.'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-primary" onClick={proceedAnywayToSubmitOrder}>Proceed Anyway</button>
                                        <button type="button" className="btn btn-secondary" onClick={modalWarnClose} data-bs-dismiss="modal">Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal fade" id="AddNewShipToModal">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h4 className="modal-title"> Add Retailer</h4>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                                </div>
                                <div className="modal-body">
                                    <form onSubmit={handleAddChannelPartnerSubmit} novalidate>
                                        <div className="mb-3 mt-3">
                                            <label for="Name " className="form-label">Name :</label>
                                            <input type="text" id="name" className="form-control"
                                                placeholder="Enter Name" name="name" onChange={handleChange} />
                                        </div>
                                        {/* <div className="mb-3 mt-3">
                                            <label for="AccountName " className="form-label">GSTIN :</label>
                                            <input type="text" id="AddShip2" className="form-control" 
                                            placeholder="Enter GSTIN " name="GSTIN" value={inputs.GSTIN} onChange={handleChange}  />
                                        </div> */}
                                        <div className="mb-3 mt-3">
                                            <label for="phoneNumber1 " className="form-label">Phone number :</label>
                                            <input type="number" id="phoneNumber1" className="form-control"
                                                placeholder="Enter Phone Number" name="phoneNumber1" onChange={handleChange} />
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label for="Address Line 1" className="form-label">Address Line 1  :</label>
                                            <textarea name='addressLine1' className="form-control txtDetailsHt" id="addressLine1" placeholder="Enter Address ..."
                                                onChange={handleChange} />
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label for="Address Line 2" className="form-label">Address Line 2  :</label>
                                            <textarea name='addressLine2' className="form-control txtDetailsHt" id="addressLine2" placeholder="Enter Address ..."
                                                onChange={handleChange} />
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label for="Address Line 3" className="form-label">Address Line 3  :</label>
                                            <textarea name='addressLine3' className="form-control txtDetailsHt" id="addressLine3" placeholder="Enter Address ..."
                                                onChange={handleChange} />
                                        </div>
                                        <div className="mb-3 mt-3">
                                            <label for="Pin Code " className="form-label">Pin :</label>
                                            <input type="number" id="pinCode" className="form-control"
                                                placeholder="Enter Pin code" name="pinCode" onChange={handleChange} />
                                        </div>
                                        <input type="submit" className="btn btn-success float-right submit-btn" data-bs-dismiss="modal" value="Add Retailer" />
                                        <button type="button" className="btn btn-danger btn-100 dp-right" id='closeAddNewAccModal' data-bs-dismiss="modal">Close</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default OrderSummary